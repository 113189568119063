export default async (shippingProviderId) => {
    const response = await fetch(`/api/shipping/surchargeManagement/getSurchargesByProvider?shippingProviderId=${shippingProviderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(shippingProviderId),
    }).then((x) => x.json())
    if (response.errorCode !== null) {
      return response
    }
    if (response.errors) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText} - ${response.errors}`,
      )
    }
    return response
  }

 