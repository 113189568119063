import { Layout, Menu, theme } from "antd";
import { DollarOutlined, HomeOutlined, NotificationOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { ConfigProvider } from "antd";
import logo from "../assets/speedway_logo.png";
import "antd/dist/reset.css";
import Image from "next/image";
import Head from "next/head";
import { SurchargeManagerProvider } from "../Zustand/surchargeManagerStore";
const { Header, Content, Footer, Sider } = Layout;

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const activeKey = router?.pathname?.toLowerCase();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <SurchargeManagerProvider>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "hsla(279,82%,22%,1)",
        },
      }}
    >
      <Head>
        <title>Shipping Service Manager</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider breakpoint="lg" collapsedWidth="0">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Image
              src={logo}
              alt="Picture of the author"
              width={100}
              height={100}
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[activeKey]}
            items={[
              {
                key: "/",
                icon: <HomeOutlined />,
                label: "Home",
                onClick: () => router.push("/"),
              },
              {
                key: "/blacklistzipcodes",
                icon: <NotificationOutlined />,
                label: "Blacklisted Zipcodes",
                onClick: () => router.push("/blacklistzipcodes"),
              },
              {
                key:"/surchargeupdates",
                icon: <DollarOutlined />,
                label: "Update Surcharges",
                onClick: () => router.push("/surchargeupdates")
              }
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          />
          <Content
            style={{
              margin: "24px 16px 0",
            }}
          >
            <Component {...pageProps} />
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            Speedway Motors ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
    </SurchargeManagerProvider>
  );
}

export default MyApp;
