"use client"
import createZustandContext from "./createZustandContext";
import { useEffect, useState } from "react";
import getSurchargeHistory from "../dataAccess/getSurchargeHistory";
import surchargeUpdateProcedure from "../dataAccess/surchargeUpdateProcedure";
import getSurchargesByProvider from "../dataAccess/getSurchargesByProvider";

const SurchargeManagerStore = createZustandContext(
    ({ set, get }) => {
       

        const contextObject = {
            shippingProviderId: undefined,
            serviceTypeId: undefined,
            surchargeId: undefined,
            applicableZoneId: undefined,
            cost: undefined,
            price: undefined,
            discount: undefined,
            effectiveEndDateId: undefined,
            effectiveEndDateId: undefined,
            serviceTypes: [],
            surcharges: [],
            applicableZones: [],
            tableData: []
        };

        return {...contextObject,
            setShippingProviderId: (value) => set({ shippingProviderId: value }),
            handleServiceTypeChange: (value) => set({selectedServiceType: value}),
            setSurcharges: (value) => set({surcharges: value}),
            setServiceTypes: (value) => set({serviceTypes: value}),
            setApplicableZones: (value) => set({applicableZones: value}),
            setSurchargeId: (value) => set({surchargeId: value}),
            handleTableDataChange: (value) => set({tableData: value}),
            setPrice: (value) => set({priceId: value}),
            setCost: (value) => set({costId: value}),
            setDiscount: (value) => set({discountId: value}),
            discount: null,
            handleCostChange: (cost) => {
                var price = get().priceId;
                const discount = (((price - cost)/price)*100);
                set({ discount });
            },
            handleStartDateChange: (value) => set({effectiveStartDateId: value}),
            handleEndDateChange: (value) => set({effectiveEndDateId: value}),
            handleApplicableZoneChange: (value) => set({applicableZoneId: value}),
            handleShippingProviderChange: (selectedShippingProvider) => {
                get().setShippingProviderId(selectedShippingProvider);
                get().getSurchargesById(selectedShippingProvider);
            },
            getSurchargesById: async (shippingProviderId) => {
                try {
                    const data = await getSurchargesByProvider(shippingProviderId);
                    get().setSurcharges(data);
                    get().setServiceTypes(data);
                    get().setApplicableZones(data.map(surcharge => surcharge.AppliedToZones))
                } catch (error) {
                    throw error
                }
            },

            handleSurchargeChange: async (surchargeName) => {
                get().setSurchargeId(surchargeName);
                get().handleTableDataChange([]);
                const surchargeToSend = { surcharge: surchargeName };
                const history = await getSurchargeHistory(surchargeToSend);
                get().handleTableDataChange(history);
            },

            validateCostPrice: (submittedValues) => {
                const costDiff = submittedValues.cost - get().tableData[0].Cost;
                const priceDiff = submittedValues.price - get().tableData[0].Price;
                if(submittedValues.Cost > submittedValues.Price)
                {
                    let validityObject = { isValid: false, msg: "Cost is higher than price. Please check values." };
                    return validityObject;
                }
                if (costDiff >= 10 || priceDiff >= 10) {
                    let validityObject = { isValid: false, msg: "Price or Cost increased by $10 or more from last update." };
                    return validityObject;
                }
                if (priceDiff < 0 || costDiff < 0) {
                    let validityObject = { isValid: false, msg: "Price or Cost decreased from last update." };
                    return validityObject;
                }
                if (get().tableData[0].Price < get().tableData[0].Cost) {
                    let validityObject = { isValid: false, msg: "Surcharge was previously discounted. Do we still get a discount?" };
                    return validityObject;
                }
    
                let validityObject = { isValid: true, msg: "" }
                return validityObject
            },

            validateDates: (submittedValues) => {
                const submittedStart = new Date(submittedValues.effectiveStartDate);
                const effectiveStartDate = `${submittedStart.getFullYear()}-${String(submittedStart.getMonth() + 1).padStart(2, '0')}-${String(submittedStart.getDate()).padStart(2, '0')}`;
                const comparableStartDate = Date.parse(effectiveStartDate);
                const historicalStartDate = get().tableData[0].EffectiveStartDateUtc
                if(comparableStartDate < historicalStartDate)
                {
                    let validityObject = {isValid: false, msg: "New start date cannot predate historical start date."}
                    return validityObject;
                }

                const submittedEnd = new Date(submittedValues.effectiveEndDate);
                const effectiveEndDate = `${submittedEnd.getFullYear()}-${String(submittedEnd.getMonth() + 1).padStart(2, '0')}-${String(submittedEnd.getDate()).padStart(2, '0')}`;
                const comparableEndDate = Date.parse(effectiveEndDate);
                const historicalEndDate = get().tableData[0].EffectiveEndDateUtc
                if(comparableEndDate < historicalEndDate)
                {
                    let validityObject = {isValid: false, msg: "New end date cannot predate historical start date."}
                    return validityObject;
                }

                if(comparableEndDate < comparableStartDate)
                {
                    let validityObject = {isValid: false, msg: "New end date cannot predate new start date"}
                    return validityObject;
                }
                let validityObject = {isValid: true, msg: ""}
                return validityObject;
            }
        };
    },
    {
        name: "SurchargeManagerStore",
        missingSelectorBehavior: "warn",
        verbose: true
    }
)

export const useSurchargeManagerStore = SurchargeManagerStore.useStore;
export const SurchargeManagerProvider = SurchargeManagerStore.Provider
